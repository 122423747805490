import { useMutation } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import ToastMessage, { ToastType } from '../../components/Common/ToastMessage/ToastMessage';
import { getUser, getUserType } from '../../redux/selectors/auth.selector';
import { login, logout } from '../../redux/slices/authSlice';
import { persistor } from '../../redux/store';
import { getLowerCaseString } from '../../utils/common.util';
import { getUserInfoAPI, sendSMSCodeAPI, updateUserInfoAPI, validateBusinessAPI, verifySMSCodeAPI } from './authAPI';

export type UpdateUserInfoPayload = any;

export const useLogin = () => {
  const dispatch = useDispatch();
  return useMutation(
    async (payload: { email: string; password: string }) => {
      const lowerCaseEmail = getLowerCaseString(payload.email);
      // Step 1
      await Auth.signIn(lowerCaseEmail, payload.password);
      // Step 2
      const response = await getUserInfoAPI(lowerCaseEmail);
      return response;
    },
    {
      onSuccess: (response: any) => {
        toast(<ToastMessage text="Successfully Signed in" type={ToastType.SUCCESS.type} />);
        dispatch(login(response?.data));
      },
    },
  );
};

type UseLogoutProps = {
  willUseToast?: boolean;
};

export const useLogout = (props?: UseLogoutProps) => {
  const dispatch = useDispatch();
  const willUseToast = props?.willUseToast ?? true;
  return useMutation(() => Auth.signOut(), {
    onSettled: () => {
      dispatch(logout());
      persistor.purge();
      if (willUseToast) {
        toast(<ToastMessage text="Successfully Signed out" type={ToastType.SUCCESS.type} />);
      }
    },
  });
};

export const useForgotPassword = () => {
  return useMutation(
    (payload: { email: string }) => {
      const lowerCaseEmail = getLowerCaseString(payload.email);
      return Auth.forgotPassword(lowerCaseEmail);
    },
    {
      onSuccess: () => {
        toast(<ToastMessage text="Email is sent" type={ToastType.SUCCESS.type} />);
      },
    },
  );
};

export const useResetPassword = () => {
  return useMutation(
    (payload: { email: string; code: string; newPassword: string }) => {
      const lowerCaseEmail = getLowerCaseString(payload.email);
      return Auth.forgotPasswordSubmit(lowerCaseEmail, payload.code, payload.newPassword);
    },
    {
      onSuccess: () => {
        toast(<ToastMessage text="Successfully Reset Password" type={ToastType.SUCCESS.type} />);
      },
    },
  );
};

type SignUpPayload = {
  email: string;
  password: string;
  userType: string;
};

export const useSignUp = () => {
  const navigate = useNavigate();
  return useMutation(
    async (payload: SignUpPayload) => {
      const { email, password, userType } = payload;
      const lowerCaseEmail = getLowerCaseString(email);
      await Auth.signUp({
        username: lowerCaseEmail,
        password,
        attributes: {
          email: lowerCaseEmail,
          profile: userType,
          website: process.env.REACT_APP_WEBSITE_URL,
        },
      });
    },
    {
      onSuccess: (_data: any, payload: SignUpPayload) => {
        navigate('/resend-verify-email', {
          state: {
            verificationInfo: { username: payload.email },
          },
        });
        toast(<ToastMessage text="Successfully Signed Up. Please Confirm your Email" type={ToastType.SUCCESS.type} />);
      },
    },
  );
};

export const useResendConfirmationEmail = () => {
  return useMutation(
    async (payload: Partial<SignUpPayload>) => {
      const { email } = payload;
      const lowerCaseEmail = getLowerCaseString(email as string);
      await Auth.resendSignUp(lowerCaseEmail /* username */, {
        website: process.env.REACT_APP_WEBSITE_URL ?? '',
      });
    },
    {
      onSuccess: () => {
        toast(<ToastMessage text="Successfully Resent email" type={ToastType.SUCCESS.type} />);
      },
    },
  );
};

type SendSMSPayload = {
  phone: string;
};
export const useSendSMS = () => {
  const user = useSelector(getUser);
  return useMutation(
    async (payload: SendSMSPayload) => {
      const { phone } = payload;
      await sendSMSCodeAPI(user?.email ?? '', phone);
    },
    {
      onSuccess: () => {
        toast(<ToastMessage text="Successfully Sent code" type={ToastType.SUCCESS.type} />);
      },
    },
  );
};

type VerifyCodeAndUpdatePhoneInfoPayload = {
  code: string;
};

export const useVerifyCodeAndUpdatePhoneInfo = () => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  return useMutation(
    async (payload: VerifyCodeAndUpdatePhoneInfoPayload) => {
      const { code } = payload;
      await verifySMSCodeAPI(user?.email ?? '', code);
    },
    {
      onSuccess: async () => {
        toast(<ToastMessage text="Successfully Secured Your Account" type={ToastType.SUCCESS.type} />);
        try {
          const response = await getUserInfoAPI(user?.email ?? '');
          dispatch(login(response?.data));
        } catch (err: any) {
          dispatch(logout());
        }
      },
      // To prevent showing 404 toast
      onError: (error: any) => {
        // console.log(error);
      },
    },
  );
};

export type ValidateBusinessPayload = {
  abn: string;
  domain: string;
};

export const useValidateBusiness = () => {
  return useMutation((payload: ValidateBusinessPayload) => {
    const { abn, domain } = payload;
    return validateBusinessAPI(abn, domain);
  });
};

export const useUpdateUserInfo = () => {
  const user = useSelector(getUser);
  const userType = useSelector(getUserType);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return useMutation(
    (payload: UpdateUserInfoPayload) => {
      const apiPayload = {
        ...payload,
        trigger: true,
        user_id: user?.id,
      };
      return updateUserInfoAPI(apiPayload);
    },
    {
      onSuccess: async () => {
        toast(<ToastMessage text="Successfully saved data" type={ToastType.SUCCESS.type} />);
        Auth.signOut().then(() => {
          navigate('/finish-signup', {
            state: {
              userType,
            },
          });
          setTimeout(() => {
            dispatch(logout());
            persistor.purge();
          }, 200);
        });
      },
    },
  );
};
