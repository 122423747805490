import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getIsAuthenticated } from '../../redux/selectors/auth.selector';

type ProtectedRouteProps = {
  redirectPath?: string;
  children: ReactNode;
};

const RouteWithoutAuthentication = ({ redirectPath = '/verify-phone-sign-up', children }: ProtectedRouteProps): any => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const location = useLocation();
  if (isAuthenticated) {
    return <Navigate to={redirectPath} replace state={{ from: location }} />;
  }

  return children ? children : <Outlet />;
};

export default RouteWithoutAuthentication;
