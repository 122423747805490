import React, { useRef, useEffect } from 'react';

type VideoProps = {
  video: string;
};
const AutoPlaySilentVideo = ({ video }: VideoProps) => {
  const videoRef = useRef(null);
  useEffect(() => {
    // @ts-ignore
    videoRef.current.defaultMuted = true;
  });
  return (
    <video width="100%" ref={videoRef} loop autoPlay muted playsInline>
      <source src={video} type="video/mp4" />
    </video>
  );
};

export default AutoPlaySilentVideo;
