import { ViewColumn } from '@mui/icons-material';
import { AppBar, styled, Container, Box, Stack } from '@mui/material';
import { themes } from '../../theme';

export const StyledAppBar = styled(AppBar)({
  backgroundColor: themes.light.colorWhite,
  boxShadow: 'none',
  minHeight: '94px',
});

export const NavbarContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '80px',
  [theme.breakpoints.down('xl')]: {
    maxWidth: '1300px',
  },
  [theme.breakpoints.down('lg')]: {
    marginTop: '40px',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

export const LogoImage = styled('img')(({ theme }) => ({
  width: '240px',
  cursor: 'pointer',
  [theme.breakpoints.down('lg')]: {
    // width: '200px',
  },
}));

export const DesktopMenuItem = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

export const MenuBox = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('lg')]: {
    display: 'block',
  },
}));
