import { Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
import AutoPlaySilentVideo from '../../../components/Common/AutoPlaySilentVideo/AutoPlaySilentVideo';
import { StyledLoadingButton } from '../../../components/Common/Styled/CommonStyled';
import commonConstants from '../../../constants/common.constant';
import { StepTitle, Subtext } from '../AuthStyled';
import { LoadingContentContainer } from './FinishScreenStyled';

const WELCOME_TEXT = {
  [commonConstants.ROLE.REFERRER_ROLE]: 'We will notify you when the App is ready for downloading',
  [commonConstants.ROLE.VENDOR_ROLE]: 'One of our team members will be in touch with you in the next 24-48 hours',
  [commonConstants.ROLE.PROVIDER_ROLE]: 'One of our team members will be in touch with you in the next 24-48 hours',
};

const FinishScreen = () => {
  const location = useLocation();

  const goToHomePage = () => {
    // @ts-ignore
    window.location.href = process.env.REACT_APP_MAIN_APP_URL;
  };

  return (
    <LoadingContentContainer>
      <Stack gap={3} alignItems={'center'}>
        <StepTitle sx={{ marginBottom: '0px' }}>YOU HAVE SUCCESSFULLY SIGNED UP</StepTitle>
        <Subtext>{WELCOME_TEXT[location?.state?.userType]}</Subtext>
        <AutoPlaySilentVideo video="video/welcome.mp4" />
        <StyledLoadingButton sx={{ width: '100%', marginTop: '20px' }} onClick={goToHomePage} variant="contained">
          Home
        </StyledLoadingButton>
      </Stack>
    </LoadingContentContainer>
  );
};

export default FinishScreen;
