import { Box, Button, LinearProgress, Stack, styled, Typography } from '@mui/material';
import { themes } from '../../theme';

export const AuthBox = styled(Box)(({ theme }) => ({
  // backgroundColor: themes.light.backgroundColorWhite,
  padding: '24px 0',
  minWidth: '500px',
  maxWidth: '500px',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    minWidth: '450px',
  },
  [theme.breakpoints.down('md')]: {
    minWidth: '400px',
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '350px',
    maxWidth: '350px',
  },
  [theme.breakpoints.down(400)]: {
    minWidth: '300px',
    maxWidth: '320px',
  },
}));

export const StyledStack = styled(Stack)({
  gap: 10,
});

export const TitleTextBig = styled(Typography)({
  fontSize: '24px',
  fontFamily: themes.light.fontLexendTera,
  fontWeight: 400,
  textAlign: 'center',
});

export const Subtitle = styled(Typography)({
  fontSize: '12px',
  fontFamily: themes.light.fontLexendTera,
  fontWeight: 400,
  textAlign: 'center',
  margin: '-5px 0 30px 0',
});

export const TitleText = styled(Typography)({
  fontSize: '16px',
  fontFamily: themes.light.fontLexendTera,
  fontWeight: 400,
  textAlign: 'center',
});

export const StepTitle = styled(TitleText)(({ theme }) => ({
  textAlign: 'left',
  marginBottom: '40px',
  [theme.breakpoints.down('lg')]: {
    textAlign: 'center',
  },
}));

export const TextButton = styled(Button)({
  display: 'flex',
  textTransform: 'unset',
  fontSize: '1rem',
  border: '1px solid black',
  height: '54px',
  color: themes.light.colorBlack,
});

export const Logo = styled('img')({
  width: '20px',
  objectFit: 'cover',
});

export const Subtext = styled(Typography)({
  textAlign: 'center',
  fontWeight: 400,
  fontSize: '16px',
  color: themes.light.borderColor,
});

export const SubtextLink = styled(Subtext)({
  display: 'inline-block',
  cursor: 'pointer',
  color: themes.light.colorMaximumBlueGreen,
});

export const StepText = styled(Typography)(({ theme }) => ({
  fontSize: '.875rem',
  fontWeight: 500,
  marginBottom: '10px',
  [theme.breakpoints.down('lg')]: {
    textAlign: 'center',
  },
}));

export const ProgressBar = styled(LinearProgress)({
  backgroundColor: themes.light.colorSilver,
  '& .MuiLinearProgress-bar': {
    backgroundColor: themes.light.colorBlack,
  },
});

export const RadioGroupContainer = styled(Box)({
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1), 1px 1px 3px rgba(0, 0, 0, 0.08)',
  borderRadius: '8px',
  padding: '20px 30px 10px 25px',
});

export const DownLoadImage = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  width: '150px',
  [theme.breakpoints.up('md')]: {
    width: '250px',
  },
}));

export const FeatureImageMobile = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  width: 400,
}));

export const ATag = styled('a')({});
