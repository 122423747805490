import { Box, Button, Container, Stack, styled, Typography } from '@mui/material';
import { themes } from '../../../theme';
import { LoadingButton } from '@mui/lab';

export const StyledBackground = styled(Box)({
  width: '100vw',
  height: '100vh',
  backgroundColor: themes.light.colorWhite,
});

export const RoundedButton = styled(Button)({
  textTransform: 'unset',
  minWidth: '110px',
});

export const CircleButton = styled(RoundedButton)({
  borderRadius: '24px',
});

export const StyledLoadingButton = styled(LoadingButton)({
  height: '52px',
  textTransform: 'unset',
  backgroundColor: themes.light.backgroundColorBlack,
  '&:hover': {
    backgroundColor: themes.light.backgroundColorBlack,
  },
  '&:disabled': {
    backgroundColor: themes.light.backgroundColorDisabled,
    color: themes.light.colorWhite,
  },
});

export const StyledOutlinedLoadingButton = styled(StyledLoadingButton)({
  color: themes.light.colorBlack,
  backgroundColor: themes.light.backgroundColorWhite,
  border: `1px solid ${themes.light.colorBlack}`,
  '&:hover': {
    backgroundColor: themes.light.backgroundColorWhite,
  },
});

export const StackHorizontal = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '10px',
});

export const DagonialContainer = styled(Container)(({ theme }) => ({
  position: 'relative',
  height: '85vh',
  width: '84vh',
  padding: '0 !important',
  margin: 0,
  backgroundImage: `linear-gradient(81.05deg, ${themes.light.colorVividYellow} 9.89%, ${themes.light.colorMaximumBlueGreen} 99.52%)`,
  [theme.breakpoints.down('xl')]: {
    width: '70vh',
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

export const DarkBox = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '84vh',
  background: 'black',
  clipPath: 'polygon(100% 0, 0 0, 0 100%)',
  [theme.breakpoints.down('xl')]: {
    width: '70vh',
  },
}));

export const LightBox = styled(Box)(({ theme }) => ({
  height: '101.4%',
  width: '85vh',
  background: 'white',
  marginTop: '-99.5%',
  clipPath: 'polygon(100% 100%, 100% 0, 0 100%)',
  [theme.breakpoints.down('xl')]: {
    height: '101%',
    width: '70.9vh',
    marginTop: '-119%',
  },
}));

export const FeaturedImage = styled('img')(({ theme }) => ({
  width: '600px',
  position: 'absolute',
  top: '16%',
  left: '18%',
  [theme.breakpoints.down('sm')]: {
    // width: '200px',
  },
}));

export const VendorProviderFeaturedImage = styled('img')(({ theme }) => ({
  width: '600px',
  position: 'absolute',
  top: '28%',
  left: '23%',
  [theme.breakpoints.down(1350)]: {
    left: '10%',
  },
  [theme.breakpoints.down(1250)]: {
    width: '550px',
  },
}));

export const RadioGroupTitle = styled(Typography)({
  fontWeight: 600,
  marginBottom: '20px',
});
