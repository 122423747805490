import { API } from 'aws-amplify';
import commonConstants from '../../constants/common.constant';
import { callAPIPost } from './commonAuthAPI';
import { UpdateUserInfoPayload } from './useAuth';

const PATHS = {
  USERS: {
    INFO: '/info',
    SEND_SMS: '/send-sms',
    SMS_VERIFICATION: '/sms-verification',
    VALIDATE_INFO: '/validate-info',
    UPDATE_INFO: '/update-info',
  },
};

export const getUserInfoAPI = (email: string) => {
  const apiName = commonConstants.API_NAME.USER;
  const path = commonConstants.API_PATH.INFO;
  const body = {
    email,
  };
  return callAPIPost(body, apiName, path);
};

export const sendSMSCodeAPI = (email: string, phone: string) => {
  const apiName = commonConstants.API_NAME.USER;
  const path = PATHS.USERS.SEND_SMS;
  const body = {
    email,
    phone,
  };
  return callAPIPost(body, apiName, path);
};

export const verifySMSCodeAPI = (email: string, code: string) => {
  const apiName = commonConstants.API_NAME.USER;
  const path = PATHS.USERS.SMS_VERIFICATION;
  const body = {
    email,
    code,
  };
  return callAPIPost(body, apiName, path);
};

export const validateBusinessAPI = (abn: string, domain: string) => {
  const apiName = commonConstants.API_NAME.USER;
  const path = PATHS.USERS.VALIDATE_INFO;
  const body = {
    abn,
    domain,
  };
  return callAPIPost(body, apiName, path);
};

export const updateUserInfoAPI = (body: UpdateUserInfoPayload) => {
  const apiName = commonConstants.API_NAME.USER;
  const path = PATHS.USERS.UPDATE_INFO;
  return callAPIPost(body, apiName, path);
};
