import { Helmet } from 'react-helmet-async';
import GooglePlay from '../../../assets/images/google_play.png';
import AppleStore from '../../../assets/images/apple_store.png';
import FeaturedReferrerPng from '../../../assets/images/featured_image.png';
import { ATag, AuthBox, DownLoadImage, FeatureImageMobile, StyledStack, TitleText } from '../AuthStyled';
import { Stack } from '@mui/material';

const APP_STORE_URL = process.env?.REACT_APP_APP_STORE_URL ?? 'https://apps.apple.com/au/app/irefer/id1670263799';
const PLAY_STORE_URL =
  process.env?.REACT_APP_PLAY_STORE_URL ?? 'https://play.google.com/store/apps/details?id=com.irefer.mobile';

const SignUp = () => {
  return (
    <>
      <Helmet>
        <title>Sign Up</title>
        <meta name="description" content="Page not found" />
      </Helmet>
      <AuthBox>
        <StyledStack>
          <Stack sx={{ display: { xs: 'flex', lg: 'none' } }} justifyContent="center" alignItems="center">
            <FeatureImageMobile src={FeaturedReferrerPng} alt="iRefer App" />
          </Stack>
          <Stack>
            <TitleText>DOWNLOAD THE APP</TitleText>
            <Stack direction="row" justifyContent="center" gap={2}>
              <a href={APP_STORE_URL} target="_blank" rel="noreferrer noopener">
                <DownLoadImage src={AppleStore} alt="App Store" />
              </a>
              <ATag href={PLAY_STORE_URL} target="_blank" rel="noreferrer noopener" sx={{ mt: { xs: 0.6, md: 1.2 } }}>
                <DownLoadImage src={GooglePlay} alt="Google Play" />
              </ATag>
            </Stack>
          </Stack>
        </StyledStack>
      </AuthBox>
    </>
  );
};

export default SignUp;
