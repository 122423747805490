import { Box, styled, Typography } from '@mui/material';
import { themes } from '../../../theme';

export const LoadingContentContainer = styled(Box)({
  backgroundColor: themes.light.backgroundColorWhite,
  padding: '40px',
  minHeight: '600px',
  width: '600px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const LoadingText = styled(Typography)({
  textAlign: 'center',
});

export const LoadingImg = styled('img')({
  width: '200px',
  textAlign: 'center',
  objectFit: 'cover',
});
