import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Amplify, Auth } from 'aws-amplify';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import amplifyConfig from './aws-exports';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import { logout } from './redux/slices/authSlice';
import store, { persistor } from './redux/store';
import { themeMaterial } from './theme';
import RouteWithoutAuthentication from './components/Auth/RouteWithoutAuthentication';
import ToastMessage, { ToastType } from './components/Common/ToastMessage/ToastMessage';
import { LayoutWithNav } from './components/Layout/LayoutWithNav';
import commonConstants from './constants/common.constant';
import FinishScreen from './pages/Auth/FinishScreen/FinishScreen';
import SignUp from './pages/Auth/SignUp/SignUp';
import UnderMaintenance from './pages/UnderMaintenance/UnderMaintenance';

Amplify.configure(amplifyConfig);

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY ?? '';
const stripePromise = loadStripe(STRIPE_KEY);

const checkExpiredAndLogout = async (error: any) => {
  // Check Expired Time here to logout;
  try {
    await Auth.currentSession();
  } catch (err: any) {
    if (store.getState()?.auth?.user) {
      queryClient.invalidateQueries();
      persistor.purge();
      store.dispatch(logout());
      toast(<ToastMessage text={commonConstants.EXPIRED_SESSION_MESSAGE} type={ToastType.ERROR.type} />);
    }
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        // Fallback Error Catch If we don't define onError when using useQuery
        toast(
          <ToastMessage text={error?.message ?? commonConstants.SOMETHING_WENT_WRONG} type={ToastType.ERROR.type} />,
        );
      },
    },
    mutations: {
      retry: false,
      onError: (error: any) => {
        // Fallback Error Catch If we don't define onError when using useMutate
        toast(
          <ToastMessage text={error?.message ?? commonConstants.SOMETHING_WENT_WRONG} type={ToastType.ERROR.type} />,
        );
      },
    },
  },
  queryCache: new QueryCache({
    onError: checkExpiredAndLogout,
  }),
  mutationCache: new MutationCache({
    onError: checkExpiredAndLogout,
  }),
});

function App() {
  return (
    <Elements stripe={stripePromise}>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ReactQueryDevtools />
          <HelmetProvider>
            <Helmet>
              <title>Signup App</title>
            </Helmet>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={themeMaterial}>
                  <BrowserRouter>
                    <Routes>
                      <Route element={<LayoutWithNav />}>
                        <Route path="/finish-signup" element={<FinishScreen />} />
                        <Route
                          element={
                            <RouteWithoutAuthentication>
                              <Outlet />
                            </RouteWithoutAuthentication>
                          }
                        >
                          <Route index element={<SignUp />} />
                          <Route path="/sign-up/referrer" element={<SignUp />} />
                        </Route>
                      </Route>
                      <Route path="/under-maintenance" element={<UnderMaintenance />} />
                      <Route path="*" element={<PageNotFound />} />
                    </Routes>
                  </BrowserRouter>
                  <ToastContainer
                    className="toaster-container"
                    position="top-center"
                    autoClose={1000000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    closeButton={false}
                  />
                </ThemeProvider>
              </PersistGate>
            </Provider>
          </HelmetProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </Elements>
  );
}

export default App;
