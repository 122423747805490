import { Button, styled, Typography } from '@mui/material';
import { themes } from '../../../theme';

export const Heading42 = styled(Typography)({
  fontSize: '42px',
  fontFamily: themes.light.fontMontserrat,
  fontWeight: 700,
  color: 'black',
  letterSpacing: 10,
});

export const SubHeading16 = styled(Typography)({
  fontSize: '16px',
  fontFamily: themes.light.fontMontserrat,
  fontWeight: 700,
  color: '#C5C5C5',
  letterSpacing: 2,
});

export const Heading18 = styled(Typography)({
  fontSize: '18px',
  fontFamily: themes.light.fontLexendTera,
  fontWeight: 400,
});

// Font Size 16
export const Text16 = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  color: 'black',
});
export const Text16Gray = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  color: themes.light.colorGray,
});
export const Text16Weight500 = styled(Typography)({
  fontWeight: 500,
  fontSize: '16px',
  color: 'black',
});
export const Text16Weight500Gray = styled(Typography)({
  fontWeight: 500,
  fontSize: '16px',
  color: themes.light.colorGray,
});

// Font Size 14
export const Text14Weight500 = styled(Typography)({
  fontWeight: 500,
  fontSize: '14px',
  color: 'black',
});
export const Text14Weight500Gray = styled(Typography)({
  fontWeight: 500,
  fontSize: '14px',
  color: themes.light.colorGray,
});

// Font Size 12
export const Text12Weight500Gray = styled(Typography)({
  fontWeight: 500,
  fontSize: '12px',
  color: themes.light.colorGray,
});
export const Text12Weight600 = styled(Typography)({
  fontWeight: 600,
  fontSize: '12px',
  color: 'black',
});

export const TextButton = styled(Button)({
  display: 'flex',
  textTransform: 'unset',
  fontSize: '1rem',
});
