const commonConstants = {
  SOMETHING_WENT_WRONG: 'Something went wrong.',
  EXPIRED_SESSION_MESSAGE: 'Your session has expired. Please Sign in',
  ADMIN: 'admin',
  DIRECTION: {
    ASC: 'asc',
    DESC: 'desc',
  },
  API_NAME: {
    USER: 'user',
  },
  API_PATH: {
    INFO: '/info',
    VENDOR: '/vendor',
  },
  ROLE: {
    REFERRER_ROLE: 'referrer',
    VENDOR_ROLE: 'vendor',
    PROVIDER_ROLE: 'provider',
  },
  BUSINESS_CATEGORY_LIST: [
    { label: 'Startup', value: 'startup' },
    { label: 'Small Business', value: 'small_business' },
    { label: 'Medium Business', value: 'medium_business' },
    { label: 'Large Business', value: 'large_business' },
    { label: 'Enterprise', value: 'enterprise' },
  ],
  GENDER: [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Neutral', value: 'neutral' },
  ],
  REFERRER_PROFESSION: [
    { label: 'Influencer', value: 'influencer' },
    { label: 'Content Creator', value: 'content_creator' },
    { label: 'Vlogger', value: 'vlogger' },
    { label: 'Blogger', value: 'blogger' },
    { label: 'Digital Marketing Agency', value: 'digital_marketing_agency' },
    { label: 'Casual Referrer', value: 'casual_referrer' },
    { label: 'Other', value: 'other' },
  ],
  REFERRER_INTEREST: [
    { label: 'iRefer Fashion', value: 'fashion' },
    { label: 'iRefer Travel & Leisure', value: 'travel' },
    { label: 'iRefer Food & Beverage', value: 'food' },
    { label: 'iRefer Lifestyle and Living ', value: 'iRefer Lifestyle and Living' },
    { label: 'iRefer Technology ', value: 'iRefer Technology' },
    { label: 'iRefer Health and Fitness ', value: 'iRefer Health and Fitness' },
    { label: 'iRefer Beauty and Wellness ', value: 'iRefer Beauty and Wellness' },
    { label: 'iRefer Automotive ', value: 'iRefer Automotive' },
    { label: 'iRefer Video Gaming ', value: 'iRefer Video Gaming' },
    { label: 'Other', value: 'other' },
  ],
  VENDOR_PROVIDER_INTEREST: [
    { label: 'To generate brand awareness', value: 'awareness' },
    { label: 'To generate more referrals', value: 'referrals' },
    { label: 'To offer services nationwide', value: 'nationwide' },
    { label: 'To offer services internationally', value: 'internationally' },
  ],
  GOODS_LIST: [
    { label: 'Fashion', value: 'Fashion' },
    { label: 'Jewellery', value: 'Jewellery' },
    { label: 'Accessories', value: 'Accessories' },
    { label: 'Beauty Products', value: 'Beauty Products' },
    { label: 'Technology', value: 'Technology' },
    { label: 'Baby Products', value: 'Baby Products' },
    { label: 'Pet Products', value: 'Pet Products' },
    { label: 'Supplements and Vitamins', value: 'Supplements and Vitamins' },
    { label: 'Automotive', value: 'Automotive' },
    { label: 'Digital Games', value: 'Digital Games' },
    { label: 'Toys', value: 'Toys' },
    { label: 'Art', value: 'Art' },
    { label: 'Home Decor & Furniture', value: 'Home Decor & Furniture' },
    { label: 'Other', value: 'other' },
  ],
  SERVICES_LIST: [
    { label: 'Hotels & Accommodation', value: 'Hotels & Accommodation' },
    { label: 'Food & Beverage', value: 'Food & Beverage' },
    { label: 'Flights & Transportation', value: 'Flights & Transportation' },
    { label: 'Tours & Adventures', value: 'Tours & Adventures' },
    { label: 'Health & Wellness', value: 'Health & Wellness' },
    { label: 'Hair & Beauty', value: 'Hair & Beauty' },
    { label: 'Cosmetics', value: 'Cosmetics' },
    { label: 'Repair & Maintenance', value: 'Repair & Maintenance' },
    { label: 'Other', value: 'other' },
  ],
  WEB_PLATFORM: [
    { label: 'Shopify', value: 'shopify' },
    { label: 'Magento', value: 'magento' },
    { label: 'WordPress', value: 'wordPress' },
    { label: 'Big Commerce', value: 'big_commerce' },
    { label: 'Wix', value: 'wix' },
    { label: 'Squarespace', value: 'squarespace' },
    { label: 'Other', value: 'other' },
  ],
  DATE_FORMAT: {
    DD_MM_YYYY: 'DD/MM/YYYY',
    MM_DD_YYYY: 'MM/DD/YYYY',
  },
};

export default commonConstants;
