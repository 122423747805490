import { createSlice } from '@reduxjs/toolkit';

export type DetailInfo = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export type Address = {
  addressLine1: string;
  addressLine2: string;
  postalCode: string;
  locality: string;
  state: string;
  country: string;
  sameAsShipping?: boolean;
};

export type AddressState = {
  detailInfo: DetailInfo;
  shippingAddress: Address;
  billingAddress: Address;
};

export const initialState: AddressState = {
  detailInfo: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
  shippingAddress: {
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    locality: '',
    state: '',
    country: '',
  },
  billingAddress: {
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    locality: '',
    state: '',
    country: '',
    sameAsShipping: false,
  },
};

const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {},
});

// export const { login, logout } = addressSlice.actions;

export default addressSlice.reducer;
