import { Box, Drawer, IconButton, Stack, styled, Typography } from '@mui/material';
import { Link, useLocation, useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
// import IreferBrand from '../../assets/images/irefer-brand.png';
import { themes } from '../../theme';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as MenuIcon } from '../../assets/svg/menu.svg';
import useBoolean from '../../hooks/Common/useBoolean';
import { DesktopMenuItem, MenuBox, NavbarContainer, StyledAppBar } from './NavbarStyled';
import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/slices/authSlice';
import { useLogout } from '../../hooks/Auth/useAuth';
import { persistor } from '../../redux/store';
import { StyledLoadingButton } from '../Common/Styled/CommonStyled';
import { getIsAuthenticated } from '../../redux/selectors/auth.selector';
import { Heading42, SubHeading16 } from '../Common/Styled/TypographyStyled';

const pages = [
  { to: process.env.REACT_APP_MAIN_APP_URL, label: 'Home' },
  // {
  //   to: `${process.env.REACT_APP_MAIN_APP_URL}/technology`,
  //   label: 'Technology',
  // },
  // { to: '/login', label: 'Login' },
  /*
  {
    to: '/beta',
    label: 'BETA REGISTRATIONS',
    dropdown: [
      {
        to: '/sign-up/referrer',
        label: 'Register as a Referrer',
      },
      {
        to: '/sign-up/vendor',
        label: 'Register as a Vendor',
      },
      {
        to: '/sign-up/provider',
        label: 'Register as a Service Provider',
      },
    ],
    },
*/
];

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { mutate: mutateLogout, isLoading: isLoggingOut } = useLogout();
  const [openMenu, toggleMenu] = useBoolean(false);
  const [openSubLink, setOpenSubLink] = useState('');
  // @ts-ignore
  const StyledLink = ({ isActiveLink, children }) => {
    const Component = styled(Stack)(() => ({
      alignItems: 'center',
      textTransform: 'unset',
      fontSize: '16px',
      fontWeight: '600',
      '& a': {
        fontFamily: themes.light.fontMontserrat,
        padding: '5px 10px',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        textDecoration: 'none',
        color: `${isActiveLink ? themes.light.colorLightGray : themes.light.colorBlack}`,
        '&:hover': {
          color: themes.light.colorLightGray,
        },
      },
    }));
    return <Component>{children}</Component>;
  };

  const SubLink = styled(Typography)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'unset',
    fontSize: '16px',
    fontWeight: '600',
    fontFamily: themes.light.fontMontserrat,
    padding: '4px 10px',
    marginBottom: '-2px',
    height: '100%',
    textDecoration: 'none',
    color: themes.light.colorBlack,
    '&:hover': {
      cursor: 'pointer',
      color: themes.light.colorLightGray,
    },
  }));

  // @ts-ignore
  const CustomLink = ({ to, isDropdown, children, ...props }) => {
    const resolvePath = useResolvedPath(to);
    const location = useLocation();
    let isActiveObject = useMatch({ path: resolvePath.pathname, end: false });
    if (!to) isActiveObject = null;
    let isActive = false;
    if (!!isActiveObject || (location.pathname === '/' && resolvePath.pathname === '/home')) {
      isActive = true;
    }
    return (
      <StyledLink isActiveLink={isActive}>
        {isDropdown ? (
          <Link to={to} {...props}>
            {children}
          </Link>
        ) : (
          <a href={to} rel="noreferrer">
            {children}
          </a>
        )}
      </StyledLink>
    );
  };

  const DropdownBox = styled(Box)({
    paddingTop: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'max-content',
  });

  const onOpenSubLink = (e: any, label: string) => {
    e.stopPropagation();
    setOpenSubLink(label);
  };

  const registerOtherFlow = async (to: string) => {
    setOpenSubLink('');
    openMenu && toggleMenu();
    Auth.signOut().then(() => {
      dispatch(logout());
      persistor.purge();
      setTimeout(() => navigate(to), 200);
    });
  };

  // @ts-ignore
  const RenderLinks = ({ isMobile }) => {
    return (
      <>
        {pages.map((page) =>
          // @ts-ignore - Can be removed once beta dropdown is reintroduced
          page.dropdown?.length ? (
            <Box sx={{ position: 'relative' }}>
              <SubLink
                onClick={(e: any) => (openSubLink ? setOpenSubLink('') : onOpenSubLink(e, page.label))}
                onMouseEnter={(e: any) => onOpenSubLink(e, page.label)}
                onMouseLeave={() => setOpenSubLink('')}
              >
                {page.label}
              </SubLink>
              {openSubLink === page.label ? (
                <DropdownBox
                  sx={{
                    position: isMobile ? 'relative' : 'absolute',
                    background: themes.light.colorWhite,
                  }}
                  onMouseEnter={(e: any) => onOpenSubLink(e, page.label)}
                  onMouseLeave={() => setOpenSubLink('')}
                >
                  {/* @ts-ignore - Can be removed once beta dropdown is reintroduced*/}
                  {page.dropdown.map(({ to, label }) => (
                    <SubLink key={label} onClick={() => registerOtherFlow(to)}>
                      {label}
                    </SubLink>
                  ))}
                </DropdownBox>
              ) : null}
            </Box>
          ) : (
            <CustomLink isDropdown={false} key={page.to} to={page.to}>
              {page.label}
            </CustomLink>
          ),
        )}
      </>
    );
  };

  return (
    <StyledAppBar position="sticky">
      <NavbarContainer maxWidth={'xl'}>
        <Stack sx={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
          <Heading42>iREFER</Heading42>
          <SubHeading16>Connect. Refer. Earn</SubHeading16>
        </Stack>
        <DesktopMenuItem gap={2}>
          <RenderLinks isMobile={false} />
          {isAuthenticated && (
            <StyledLoadingButton
              loading={isLoggingOut}
              variant="contained"
              onClick={() => mutateLogout()}
              sx={{
                width: '115px',
                fontWeight: 600,
                fontSize: '16px',
                height: '45px',
              }}
            >
              Log Out
            </StyledLoadingButton>
          )}
        </DesktopMenuItem>
        <MenuBox>
          <IconButton
            sx={{ color: 'black', margin: '15px 0' }}
            aria-label="show more"
            aria-haspopup="true"
            color="inherit"
            onClick={toggleMenu}
          >
            <MenuIcon />
          </IconButton>
          <Drawer sx={{ height: '100vh' }} anchor={'top'} open={openMenu} onClose={toggleMenu}>
            <Stack alignItems="center" justifyContent="space-between" sx={{ margin: '50px auto' }} gap={3}>
              <CloseIcon onClick={toggleMenu} sx={{ cursor: 'pointer' }} />
              <RenderLinks isMobile={true} />
            </Stack>
          </Drawer>
        </MenuBox>
      </NavbarContainer>
    </StyledAppBar>
  );
};

export default Navbar;
