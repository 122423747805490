import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  DagonialContainer,
  DarkBox,
  FeaturedImage,
  LightBox,
  StyledBackground,
  VendorProviderFeaturedImage,
} from '../Common/Styled/CommonStyled';
import Navbar from './Navbar';
import { Container, Grid } from '@mui/material';
import VendorProviderImage from '../../assets/images/vendor_providor_featured_image.png';
import FeaturedReferrerPng from '../../assets/images/featured_image.png';
import { useSelector } from 'react-redux';
import commonConstants from '../../constants/common.constant';
import { getUserType } from '../../redux/selectors/auth.selector';

const FeaturedBackgroundImage = {
  [commonConstants.ROLE.REFERRER_ROLE]: <FeaturedImage src={FeaturedReferrerPng} />,
  [commonConstants.ROLE.VENDOR_ROLE]: <VendorProviderFeaturedImage src={VendorProviderImage} />,
  [commonConstants.ROLE.PROVIDER_ROLE]: <VendorProviderFeaturedImage src={VendorProviderImage} />,
};

export const LayoutWithNav = () => {
  const userType = useSelector(getUserType);
  return (
    <StyledBackground>
      <Navbar />
      <Container maxWidth={false} sx={{ padding: '0px !important' }}>
        <Grid container>
          <Grid item xl={6} lg={6}>
            <DagonialContainer maxWidth={false}>
              <DarkBox />
              <LightBox />
              {userType && FeaturedBackgroundImage[userType]}
            </DagonialContainer>
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12} display="flex" alignItems="center" justifyContent="center">
            <Outlet />
          </Grid>
        </Grid>
      </Container>
    </StyledBackground>
  );
};
