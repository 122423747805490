import React from 'react';
import LogoIReferIcon from '../../assets/images/icon-logo-irefer.png';
import { Helmet } from 'react-helmet-async';
import { CodeText, CustomBox, Logo, MessageParagraph, Wrapper } from '../PageNotFound/PageNotFoundStyled';
import { StyledLoadingButton } from '../../components/Common/Styled/CommonStyled';

function UnderMaintenance() {
  const navigateTo_iRefer = () => {
    window.location.href = 'https://www.i-refer.app/';
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Under Maintenance</title>
        <meta name="description" content="iRefer is under maintenance" />
      </Helmet>
      <CustomBox>
        <Logo src={LogoIReferIcon} onClick={navigateTo_iRefer} />
        <CodeText>UNDER MAINTENANCE</CodeText>
        <MessageParagraph>We are currently under maintenance for a moment. Please try again later.</MessageParagraph>
        <StyledLoadingButton variant="contained" onClick={navigateTo_iRefer}>
          Go to iRefer website
        </StyledLoadingButton>
      </CustomBox>
    </Wrapper>
  );
}

export default UnderMaintenance;
